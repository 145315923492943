import { useAsync } from "@hooks/useAsync";
import userService from "@service/user";
import { useCallback } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { RouteComponentProps } from "react-router-dom";

export const UserGetView = (
  props: RouteComponentProps<{ applicationUserId: string }, {}, User>
) => {
  const loadUser = useCallback(
    () => userService.get(props.match.params.applicationUserId),
    [props.match.params.applicationUserId]
  );

  const { status, value: userData, error } = useAsync<User>(loadUser, true);

  return (
    <>
      <BreadcrumbsItem to={`/dashboard/users/${userData?.applicationUserId}`}>
        {userData ? `${userData.firstName} ${userData.lastName}` : "Loading..."}
      </BreadcrumbsItem>
      {status === "success" && <pre>{JSON.stringify(userData, null, 2)}</pre>}
    </>
  );
};

export default UserGetView;
