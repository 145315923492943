import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from "formik";

import { useAuth } from "@hooks/useAuth";

type Values = {
  username: string;
  password: string;
};

export default function LoginForm() {
  const authCtx = useAuth();

  const onSubmit = async (values: Values, actions: FormikHelpers<Values>) => {
    try {
      await authCtx.login(values.username, values.password);
      actions.resetForm();
    } catch (error) {
      actions.setErrors({
        username: " ",
        password: "Make sure your credentials are correct",
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
      }}
      validationSchema={Yup.object({
        username: Yup.string().required("Phone or email cannot be empty"),
        password: Yup.string().required("Password cannot be empty"),
      })}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form className="flex flex-col mt-5 space-y-4">
          <div>
            <Field name="username">
              {({ field, form }: any) => (
                <div className="relative">
                  <label
                    htmlFor="username"
                    aria-label="Username"
                    className="hidden"
                  >
                    Phone or Email
                  </label>
                  <input
                    {...field}
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Phone or Email"
                    className={`${
                      form.touched.username &&
                      form.errors.username &&
                      "error-outline"
                    } login-input-field`}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className={`${
                      form.touched.username && form.errors.username
                        ? "block"
                        : "hidden"
                    } absolute w-6 h-full text-red-400 right-2 top-0`}
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              )}
            </Field>
            <ErrorMessage
              name="username"
              component="div"
              className="text-xs italic text-right text-red-400 mt-1"
            />
          </div>
          <div>
            <Field name="password">
              {({ field, form }: any) => (
                <div className="relative">
                  <label
                    htmlFor="password"
                    aria-label="Password"
                    className="hidden"
                  >
                    Password
                  </label>
                  <input
                    {...field}
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    className={`${
                      form.touched.password &&
                      form.errors.password &&
                      "error-outline"
                    } login-input-field`}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className={`${
                      form.touched.username && form.errors.username
                        ? "block"
                        : "hidden"
                    } absolute w-6 h-full text-red-400 right-2 top-0`}
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              )}
            </Field>
            <ErrorMessage
              name="password"
              component="div"
              className="text-xs italic text-right text-red-400 mt-1"
            />
          </div>
          <button
            disabled={!(isValid && dirty) || isSubmitting}
            type="submit"
            className="py-3 text-sm tracking-wider transition duration-200 ease-in-out bg-slate-blue text-white uppercase rounded-lg outline-none hover:bg-indigo-700 filter hover:drop-shadow-lg disabled:bg-indigo-300 disabled:drop-shadow-none focus:outline-none "
          >
            {isSubmitting ? "Logging in..." : "Continue"}
          </button>
        </Form>
      )}
    </Formik>
  );
}
