import { Switch, Route, useRouteMatch, Link, Redirect } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import Layout from "@components/Layout";
import ErrorBoundary from "@components/ErrorBoundary";
import UsersPage from "./pages/Users";
import ProfilePage from "./pages/Profile";
import { Can } from "@components/Authorization/Can";

const Landing = () => {
  let { url } = useRouteMatch();

  return (
    <>
      <div className="h-full w-full grid bg-light-blue">
        <div className="m-auto text-center">
          <h1 className="">This is the insight dashboard</h1>
          <h1 className="mb-8 text-xs">
            (or app switcher when multiple apps are made available)
          </h1>
          <div className="divide-y divide-gray-200">
            <div className="py-4">
              <Link to="/stockbook" className="text-indigo-800 text-lg">
                Go to Stockbook
              </Link>
              <br />
              <div className="text-gray-500 text-lg cursor-not-allowed">
                Go to Payroll App
              </div>
              <div className="text-gray-500 text-lg cursor-not-allowed">
                Go to Attendance App
              </div>
            </div>
            <div className="py-4">
              <Can I="read" a="User">
                <Link to={`${url}/users`} className="text-indigo-800 text-lg">
                  Manage users
                </Link>
              </Can>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Dashboard = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <BreadcrumbsItem to="/dashboard">
        <span className="text-indigo-800 text-xl tracking-widest font-semibold">
          insight
        </span>
      </BreadcrumbsItem>
      <Layout>
        <Layout.Container>
          <Layout.Body>
            <Layout.Navbar />
            <div className="px-4 sm:px-6 my-8 overflow-auto">
              <ErrorBoundary>
                <Switch>
                  <Route exact path={path} component={Landing} />
                  <Route path={`${path}/users`} component={UsersPage} />
                  <Route path={`${path}/my-profile`} component={ProfilePage} />
                  <Route render={() => <Redirect to="/404" />} />
                </Switch>
              </ErrorBoundary>
            </div>
          </Layout.Body>
        </Layout.Container>
      </Layout>
    </>
  );
};

export default Dashboard;
