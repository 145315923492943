import { useMemo } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import {
  HomeIcon,
  CollectionIcon,
  DocumentIcon,
} from "@heroicons/react/outline";

import Layout from "@components/Layout";

const Dashboard = () => (
  <>
    <h1 className="text-xl">Graphs and what not!</h1>
  </>
);
const Categories = () => (
  <>
    <BreadcrumbsItem to="/stockbook/categories">Categories</BreadcrumbsItem>
    <h1 className="text-xl">Categories CRUD</h1>
  </>
);
const Products = () => (
  <>
    <BreadcrumbsItem to="/stockbook/products">Products</BreadcrumbsItem>
    <h1 className="text-xl">Products CRUD</h1>
  </>
);

const Stockbook = () => {
  let { path, url } = useRouteMatch();

  const links = useMemo(
    () => [
      {
        to: url,
        exact: true,
        icon: HomeIcon,
        title: "Home",
      },
      {
        to: `${url}/categories`,
        exact: false,
        icon: CollectionIcon,
        title: "Categories",
      },
      {
        to: `${url}/products`,
        exact: false,
        icon: DocumentIcon,
        title: "Products",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSearch = () => {};

  return (
    <>
      <BreadcrumbsItem to="/stockbook">
        <span className="font-medium">Stockbook</span>
      </BreadcrumbsItem>
      <Layout>
        <Layout.Container>
          <Layout.SideNav links={links} />
          <Layout.Body>
            <Layout.Navbar onSearch={handleSearch} />
            <div className="px-4 sm:px-6 my-8 overflow-auto">
              <Switch>
                <Route exact path={path} component={Dashboard} />
                <Route path={`${path}/categories`} component={Categories} />
                <Route path={`${path}/products`} component={Products} />
              </Switch>
            </div>
          </Layout.Body>
        </Layout.Container>
      </Layout>
    </>
  );
};

export default Stockbook;
