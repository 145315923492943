import { Ability, defineAbility } from "@casl/ability";

export type Actions = "create" | "read" | "update" | "delete";
export type Subjects = "User" | "all";

type AppAbility = Ability<[Actions, Subjects]>;

export let ability = defineAbility<AppAbility>((allow, forbid) => {
  // will be provided by default to all users by default
  allow("read", "all");
  forbid("read", "User");
});

export function ruleBuilder(userPermissions: string[]) {
  if (Array.isArray(userPermissions)) {
    const newRules: any[] = [];

    if (userPermissions.includes("admin")) {
      newRules.push({ action: "manage", subject: "all" });
      return newRules;
    }
  }

  return ability.rules;
}
