import { useCallback, useMemo, useRef, useState } from "react";
import { Link, RouteComponentProps, useRouteMatch } from "react-router-dom";
import { Column } from "react-table";

import userService from "@service/user";
import Modal from "@components/Modal";
import { Table } from "@components/Table";
import { withAuthorization } from "@components/Authorization";
import { Can } from "@components/Authorization/Can";

const UserListView = (props: RouteComponentProps) => {
  const searchParams = new URLSearchParams(props.location.search);
  const pageSizeParam = parseInt(searchParams.get("pageSize") || "0");
  const currentPageParam = parseInt(searchParams.get("currentPage") || "0");
  const searchStringParam = searchParams.get("searchString") || undefined;

  // We'll start our table without any data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize] = useState(pageSizeParam || 0);
  const [currentPage] = useState(currentPageParam || 0);
  const [searchString] = useState<string | undefined>(searchStringParam);
  const [totalRecords, setTotalRecords] = useState(0);
  const fetchIdRef = useRef(0);

  // modal state for deactivating accounts
  const [deactivateData, setDeactivateData] = useState<
    | {
        data: User;
        isModalVisible: true;
      }
    | {
        data: undefined;
        isModalVisible: false;
      }
  >({
    data: undefined,
    isModalVisible: false,
  });

  const { url } = useRouteMatch();

  const columns = useMemo<Column<User>[]>(
    () => [
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Phone Number",
        accessor: (data) => (
          <span className="text-xs text-gray-600">{data.phoneNumber}</span>
        ),
      },
      {
        Header: "Email",
        className: "bg-red-500",
        accessor: (data) => (
          <span className="text-xs text-gray-600">{data.emailId}</span>
        ),
      },
      {
        Header: "Status",
        accessor: (data) =>
          data.active ? (
            <span className="text-xs text-green-600">Active</span>
          ) : (
            <span className="text-xs text-pink-700">Inactive</span>
          ),
      },
      {
        Header: "Roles",
        accessor: (data) =>
          data.roles.map((role) => (
            <span
              className={
                (role.roleName === "admin"
                  ? "bg-pink-100 text-pink-700"
                  : "bg-blue-100 text-blue-700") +
                ` mr-2 text-xs inline-flex items-center font-light uppercase px-3 py-1 rounded-full`
              }
            >
              {role.roleName}
            </span>
          )),
      },
      {
        Header: "Action",
        accessor: (data) => (
          <div className="flex" onClick={(e) => e.stopPropagation()}>
            <Link
              to={`${url}/edit/${data.applicationUserId}`}
              className="z-1 flex-1 h-4 w-5 text-indigo-400 hover:text-indigo-800"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-5 text-current"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            </Link>
            <button
              className="flex-1 h-4 w-5 text-indigo-400 hover:text-red-400 disabled:text-gray-400"
              disabled={!data.active}
              onClick={() => {
                setDeactivateData({ data, isModalVisible: true });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </button>
          </div>
        ),
      },
    ],
    []
  );

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, searchString }) => {
      const currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set("pageSize", pageSize);
      currentUrlParams.set("currentPage", pageIndex);
      currentUrlParams.set("searchString", searchString);

      const max = new Promise((resolve) => {
        setTimeout(() => resolve(true), 800);
      });

      // This will get called when the table needs new data

      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Set the loading state
      setLoading(true);

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const [{ response, metaData }] = await Promise.all([
          userService.list({
            sortDir: "asc",
            sortColumn: "firstName",
            pageNumber: pageIndex,
            pageSize: pageSize,
            searchString,
          }),
          max,
        ]);

        setData(response);

        // Server should send back total page count.
        setPageCount(Math.ceil(metaData.totalRecords / pageSize));
        setTotalRecords(metaData.totalRecords);

        // Update the query params to state get updated in turn
        props.history.push({
          pathname: window.location.pathname,
          search: currentUrlParams.toString(),
        });

        setLoading(false);
      }
    },
    []
  );

  const gotoUserCreate = () => {
    props.history.push(`${url}/create`);
  };

  const onRowClick = (rowData: User) => {
    props.history.push(`${url}/${rowData.applicationUserId}`);
  };

  const onModalClose = () =>
    setDeactivateData({ data: undefined, isModalVisible: false });

  const reloadData = () => {
    const currentUrlParams = new URLSearchParams(window.location.search);
    const pageSize = parseInt(currentUrlParams.get("pageSize") || "0");
    const currentPage = parseInt(currentUrlParams.get("currentPage") || "0");
    const searchString = currentUrlParams.get("searchString");
    console.log({ pageSize, currentPage, searchString });
    fetchData({ pageSize, pageIndex: currentPage, searchString });
  };

  return (
    <>
      <Modal
        open={deactivateData.isModalVisible}
        onClose={onModalClose}
        onAction={async () => {
          await userService.updateStatus(
            deactivateData.data!.applicationUserId,
            false
          );
          reloadData();
          onModalClose();
        }}
        actionBtnTxt="Deactivate"
        title="Deactivate account"
        body={
          <p className="text-sm text-gray-500">
            Are you sure you want to deactivate{" "}
            {deactivateData.data
              ? deactivateData.data.firstName + "'s "
              : "this "}
            account?
          </p>
        }
      />
      {data && (
        <Table
          data={data}
          columns={columns}
          onRowClick={onRowClick}
          fetchData={fetchData}
          loading={loading}
          totalCount={totalRecords}
          pageCount={pageCount}
          pageIndex={currentPage}
          pageSize={pageSize}
          searchString={searchString}
          renderAddBtn={
            <Can I="create" a="User">
              <button
                onClick={gotoUserCreate}
                className="h-8 px-3 transition-colors duration-150 bg-indigo-700 rounded-md hover:bg-indigo-600 focus:shadow-outline tracking-tight text-xs text-white"
              >
                Add New User
              </button>
            </Can>
          }
        />
      )}
    </>
  );
};

export default withAuthorization("read", "User")(UserListView);
