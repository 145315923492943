import { RouteComponentProps } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { FormikHelpers } from "formik";
import { toast } from "react-toastify";

import userService from "@service/user";
import UserForm from "./components/UserForm";
import { withAuthorization } from "@components/Authorization";
import { ReactComponent as UndrawProfileData } from "@images/undraw-profile-data.svg";

const initialValues = {
  firstName: "",
  lastName: "",
  emailId: "",
  phoneNumber: "",
  password: "",
  confirmPassword: "",
  role: ["user"],
};

const UserCreateView = (props: RouteComponentProps) => {
  const handleCreate = async (
    values: typeof initialValues,
    { setSubmitting, resetForm }: FormikHelpers<typeof initialValues>
  ) => {
    try {
      await userService.create(values);
      toast.info("User added successfully!");
      resetForm();
    } catch (error) {
      toast.error(
        <div>
          <div>Unable to add user!</div>
          <div className="mt-1 text-xs">{error.response.data.message}</div>
        </div>
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <BreadcrumbsItem to="/dashboard/users/create">Add new</BreadcrumbsItem>
      <div className="relative flex flex-row overflow-x-hidden w-full rounded-md shadow-sm">
        <div className="flex-1">
          <div className="relative flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8 p-10 bg-white rounded-xl shadow-lg z-10">
              <div className="grid gap-8 grid-cols-1">
                <div className="flex flex-col ">
                  <div className="flex flex-col sm:flex-row items-center">
                    <h2 className="font-semibold text-lg mr-auto">
                      Add new user
                    </h2>
                    <div className="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>
                  </div>
                  <div className="mt-5">
                    <UserForm
                      mode="create"
                      initialValues={initialValues}
                      onSubmit={handleCreate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-shrink w-1/3 items-center hidden lg:block">
          <div className="relative flex h-full items-center text-right">
            <div className="absolute w-full text-right">
              <UndrawProfileData
                className="h-full w-full text-right opacity-50"
                style={{ transform: "scaleX(-1)" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuthorization("create", "User")(UserCreateView);
