import authService from "@service/auth";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

let responseInterceptor: number;

export function setupAxiosConfig(token: string, refreshToken: string) {
  delete axios.defaults.headers.common["Authorization"];
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  responseInterceptor = axios.interceptors.response.use(
    (res) => res,
    async (err) => {
      const originalRequest = err.config;

      if (
        err.response.status === 403 &&
        originalRequest.url ===
          `${process.env.REACT_APP_SERVER_URL}/auth/refresh-token`
      ) {
        console.log("Error while refreshing token... clearing everything...");
        localStorage.removeItem("refreshToken");
        tearDownAxiosConfig();
        return Promise.reject(err);
      }

      if (err.response.status === 401 && !originalRequest._retry) {
        console.log(
          "Error while making API call for first time.. refreshing token..."
        );
        originalRequest._retry = true;

        try {
          const refreshToken = localStorage.getItem("refreshToken");
          console.log("Got old refresh token...", refreshToken);
          const response = await authService.generateNewAccessToken(
            JSON.parse(refreshToken || "")
          );
          console.log("Got new refresh token...");

          // Update in axios for future calls
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response.data.accessToken}`;

          console.log("Should try again...");

          // Update in error for retry
          err.config.headers[
            "Authorization"
          ] = `Bearer ${response.data.accessToken}`;
          return axios.request(err.config);
        } catch (error) {
          console.log("Error while refreshing token... not retrying...");
          return Promise.reject(err);
        }
      }
      console.log("Error while making API call... not retrying...");
      return Promise.reject(err);
    }
  );
}

export function tearDownAxiosConfig() {
  delete axios.defaults.headers.common["Authorization"];
  axios.interceptors.response.eject(responseInterceptor);
}

export default axios;
