import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import Login from "@pages/Login";
import Dashboard from "@pages/Dashboard";
import Stockbook from "@pages/Stockbook";
import { useAuth } from "@hooks/useAuth";
import { setupAxiosConfig } from "axios.config";
import { PrivateRoute, PublicOnlyRoute } from "@components/Route";
import { useStoredRefreshToken } from "@hooks/usePersistedStore";
import { ability, ruleBuilder } from "@components/Authorization/ability";
import axios from "axios";
import Error404 from "@pages/Error/404";

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function App() {
  const companyName = window.location.hostname.split(".")[0];
  const authCtx = useAuth();
  const [refreshToken] = useStoredRefreshToken<string | null>(null);

  const generateNewTokenIfRefreshTokenAvailable = async () => {
    if (refreshToken) {
      try {
        const response = await authCtx.generateNewToken(2000);
        console.log("\nGenerated new access token");
        setupAxiosConfig(response.accessToken, response.refreshToken);
        await authCtx.fetchUser();
        console.log("Fetched user");
      } catch (error) {
        console.log("Unable to fetch user, logging out");
        await authCtx.logout();
        window.location.reload();
      }
    }
  };

  const pingServerForStartup = async () => {
    try {
      axios.get("/auth/ping");
    } catch (error) {}
  };

  useEffect(() => {
    pingServerForStartup();
    generateNewTokenIfRefreshTokenAvailable();
  }, []);

  useEffect(() => {
    if (authCtx.user) {
      console.log("Loggedin User data updated, updating permissions...");
      const roles = authCtx.user.roles.map((role) => role.roleName);
      ability.update(ruleBuilder(roles));
      console.log("rules updated");
    }
  }, [authCtx.user]);

  return (
    <>
      <Helmet>
        <title>{capitalizeFirstLetter(companyName)} | Insight</title>
      </Helmet>
      <ToastContainer
        newestOnTop
        closeOnClick
        draggable
        pauseOnHover
        pauseOnFocusLoss
        rtl={false}
        hideProgressBar={false}
        position="top-right"
        className="mr-2"
        autoClose={5000}
        transition={Slide}
      />
      <Switch>
        <PublicOnlyRoute exact path="/" component={Login} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/stockbook" component={Stockbook} />
        <Route path="/404" component={Error404} />
        <Route render={() => <Redirect to="/404" />} />
      </Switch>
    </>
  );
}

export default App;
