import axios from "axios.config";

const userService = {
  list: async (params?: any) => {
    const response = await axios.get("/users", { params });
    return response.data;
  },
  get: async (userId: string, params?: any) => {
    const response = await axios.get(`/users/${userId}`, { params });
    return response.data;
  },
  create: async (body: any) => {
    const response = await axios.post("/auth/signup", body);
    return response.data;
  },
  update: async (body: any) => {
    const response = await axios.put("/users", body);
    return response.data;
  },
  updatePassword: async (body: any) => {
    const response = await axios.put("/users/password", body);
    return response.data;
  },
  updateStatus: async (userId: string, active: boolean) => {
    const response = await axios.put(`/users/active`, null, {
      params: { userId, active },
    });
    return response.data;
  },
};

export default userService;
