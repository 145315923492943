import axios from "axios.config";

const authService = {
  login: (email: string, password: string) =>
    axios.post("/auth/login", {
      emailId: email,
      password: password,
    }),

  generateNewAccessToken: (refreshToken: string) =>
    axios.post("/auth/refresh-token", {
      refreshToken,
    }),

  getSelf: async () => {
    const response = await axios.get("/users/me");
    return response.data;
  },
};

export default authService;
