import { Link } from "react-router-dom";
import { ReactComponent as Undraw404 } from "@images/undraw-404.svg";
import { Transition } from "@headlessui/react";
import Layout from "@components/Layout";

const Error404 = () => (
  <>
    <Layout>
      <Layout.Container>
        <Layout.Body>
          <Layout.Navbar />
          <div className="px-4 sm:px-6 my-8 overflow-auto">
            <main className="relative overflow-hidden h-screen">
              <div className="container mx-auto px-6 md:px-12 relative flex items-center py-8">
                <div className="container mx-auto px-6 flex flex-col justify-between items-center relative">
                  <div className="flex w-full items-center justify-center space-x-12 flex-col md:flex-row mb-16 md:mb-8">
                    <h1 className="font-extralight text-center text-3xl md:text-4xl lg:text-6xl text-gray-800">
                      GOT LOST?
                    </h1>
                    <Link
                      to="/"
                      className="px-3 py-2 w-32 font-light transition ease-in duration-200 uppercase hover:bg-indigo-600 hover:text-white border-b-2 text-2xl border-indigo-600 focus:outline-none"
                    >
                      Help Me
                    </Link>
                  </div>
                  <Transition
                    appear={true}
                    show={true}
                    enter="transition ease-in-out duration-1000"
                    enterFrom="transform-gpu translate-y-6 opacity-0"
                    enterTo="transform-gpu translate-y-0 opacity-100"
                  >
                    <div className="block w-full lg:mt-14 mx-auto mt-6 md:mt-0 relative">
                      <Undraw404 className="h-32 lg:h-56 w-full mx-auto opacity-70" />
                    </div>
                  </Transition>
                </div>
              </div>
            </main>
          </div>
        </Layout.Body>
      </Layout.Container>
    </Layout>
  </>
);

export default Error404;
