import React from "react";
import { NavLink, Link, useRouteMatch } from "react-router-dom";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Breadcrumbs } from "react-breadcrumbs-dynamic";
import {
  BellIcon,
  MenuIcon,
  XIcon,
  SearchIcon,
  ViewGridIcon,
} from "@heroicons/react/outline";

import { getInitialsFromFullName } from "@utils";
import { useAuth } from "@hooks/useAuth";

type SideNavProps = {
  links: Array<{
    to: string;
    exact: boolean;
    icon: typeof React.Component;
    title: string;
  }>;
};

const Layout = ({ children }: any) => (
  <div className="bg-indigo-800">{children}</div>
);

const Container = ({ children }: any) => (
  <div className="min-h-screen px-7 flex flex-row flex-auto flex-shrink-0 antialiased bg-transparent text-black">
    {children}
  </div>
);

const Body = ({ children }: any) => (
  <div className="w-full h-full mt-5 mb-10 min-h-screen rounded-3xl bg-light-blue">
    {children}
  </div>
);

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = ({ onSearch }: any) => {
  const authCtx = useAuth();
  const { url } = useRouteMatch();

  return (
    <Disclosure
      as="nav"
      className="relative z-10 bg-light-blue rounded-t-3xl filter drop-shadow-sm select-none"
    >
      {({ open }) => (
        <>
          <div className="max-w-full mx-auto px-4 sm:px-6">
            <div className="flex items-center justify-between h-16">
              <div className="hidden lg:block mr-auto">
                <Breadcrumbs
                  separator={<span className="mx-2">/</span>}
                  containerProps={{
                    className:
                      "text-sm font-light subpixel-antialiased text-indigo-800 tracking-tight font-sans",
                  }}
                  item={NavLink}
                  finalItem={"span"}
                  finalProps={{
                    className: "text-gray-700",
                  }}
                />
              </div>
              <div className="hidden md:block ml-auto">
                <div className="flex items-center gap-x-5">
                  {onSearch && (
                    <Transition
                      appear={true}
                      show={true}
                      enter="transition ease-in-out duration-1000"
                      enterFrom="transform-gpu translate-x-6 opacity-0"
                      enterTo="transform-gpu translate-x-0 opacity-100"
                    >
                      <div className="group relative mx-auto text-gray-600">
                        <input
                          className="bg-indigo-500 bg-opacity-10 h-10 w-52 px-5 pr-10 rounded-3xl text-sm focus:outline-none"
                          type="search"
                          name="search"
                          placeholder="Search"
                        />
                        <button
                          type="submit"
                          className="absolute right-0 top-0 mt-3 mr-4"
                        >
                          <SearchIcon className="transition-opacity duration-300 text-gray-600 h-4 w-4 text-current opacity-30 group-hover:opacity-60" />
                        </button>
                      </div>
                    </Transition>
                  )}

                  <button className="relative p-1 text-gray-400 hover:text-indigo-400 focus:outline-none">
                    <span className=" absolute right-1 top-0.5 flex h-2 w-2">
                      <span className="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-purple-400 opacity-75"></span>
                      <span className="relative inline-flex rounded-full h-2 w-2 bg-purple-500"></span>
                    </span>
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <Link
                    to="/dashboard"
                    className="relative p-1 text-gray-400 hover:text-indigo-400 focus:outline-none"
                  >
                    <span className="sr-only">View all apps</span>
                    <ViewGridIcon className="h-6 w-6" aria-hidden="true" />
                  </Link>

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="max-w-xs rounded-full flex items-center text-sm focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-indigo-400">
                            <span className="sr-only">Open user menu</span>
                            <div className="bg-gray-200 text-gray-500 w-8 h-8 rounded-full inline-flex items-center align-middle justify-center font-light text-sm filter drop-shadow">
                              {getInitialsFromFullName(
                                `${authCtx.user?.firstName} ${authCtx.user?.lastName}` ||
                                  "User"
                              )}
                            </div>
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg overflow-hidden bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100"
                          >
                            <Menu.Item disabled>
                              <div className="py-2.5 block px-4 text-xs filter drop-shadow-sm">
                                Signed in as
                                <br />
                                <span className="font-semibold">
                                  {authCtx.user?.firstName}{" "}
                                  {authCtx.user?.lastName}
                                </span>
                              </div>
                            </Menu.Item>
                            <div>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to={`/dashboard/my-profile`}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-xs text-gray-700"
                                    )}
                                  >
                                    My Profile
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-xs text-gray-700"
                                    )}
                                  >
                                    Account Settings
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                            <Menu.Item onClick={authCtx.logout}>
                              {({ active }) => (
                                <div
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-xs text-gray-700"
                                  )}
                                >
                                  Sign out
                                </div>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
              <div className="-mr-1 flex md:hidden ml-auto">
                {/* Mobile menu button */}
                <Disclosure.Button className=" inline-flex items-center justify-center p-2 rounded-xl text-gray-500 hover:text-white hover:bg-indigo-500 hover:bg-opacity-50 focus:outline-none">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="pt-4 pb-3 border-t border-gray-200 overflow-hidden">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <div className="bg-gray-200 text-gray-500 w-10 h-10 rounded-full inline-flex items-center align-middle justify-center font-light text-sm">
                    {getInitialsFromFullName(
                      `${authCtx.user?.firstName} ${authCtx.user?.lastName}` ||
                        "User"
                    )}
                  </div>
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium leading-none text-gray-800">
                    {authCtx.user?.firstName} {authCtx.user?.lastName}
                  </div>
                  <div className="text-sm font-medium leading-none text-gray-400">
                    {authCtx.user?.emailId}
                  </div>
                </div>
                <button className="ml-auto flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-indigo-400 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-indigo-400 focus:ring-white">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-3 px-2 space-y-1">
                <a
                  href="#"
                  className="block px-3 py-2 rounded-md text-sm font-medium text-gray-400 hover:text-white hover:bg-indigo-500"
                >
                  My Profile
                </a>
                <a
                  href="#"
                  className="block px-3 py-2 rounded-md text-sm font-medium text-gray-400 hover:text-white hover:bg-indigo-500"
                >
                  Account Settings
                </a>
                <a
                  href="#"
                  className="block px-3 py-2 rounded-md text-sm font-medium text-gray-400 hover:text-white hover:bg-indigo-500"
                  onClick={authCtx.logout}
                >
                  Sign out
                </a>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

const SideNav = ({ links }: SideNavProps) => (
  <div className="relative">
    <div className="-ml-7 flex flex-col left-0 w-14 md:w-48 bg-indigo-800 h-full text-white transition-all duration-300 border-none z-10 sidebar">
      <div className="overflow-y-auto overflow-x-hidden justify-between">
        <ul className="fixed flex flex-col py-4 pt-28 md:pl-3 w-14 md:w-48">
          {links.map(({ to, exact, icon: Icon, title }, index) => (
            <NavLink
              exact={exact}
              to={to}
              activeClassName="selected font-normal"
              className="unselected font-light"
            >
              <Transition
                appear={true}
                show={true}
                enter={`transition ease-in-out duration-1000 delay-${
                  index * 100
                }`}
                enterFrom="transform-gpu translate-x-6 opacity-0"
                enterTo="transform-gpu translate-x-0 opacity-100"
              >
                <div className="relative flex flex-row items-center h-11 focus:outline-none text-current group-hover:text-white-800">
                  <span className="inline-flex justify-center items-center ml-4">
                    <Icon className="w-5 h-5" />
                  </span>
                  <span className="ml-2 text-sm tracking-wide truncate">
                    {title}
                  </span>
                </div>
              </Transition>
            </NavLink>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

Layout.Container = Container;
Layout.Body = Body;
Layout.SideNav = SideNav;
Layout.Navbar = Navbar;

export default Layout;
