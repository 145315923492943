import React from "react";
import { Redirect } from "react-router-dom";

import { ability, Actions, Subjects } from "./ability";

export const withAuthorization =
  (action: Actions, subject: Subjects) => (WrappedComponent: any) => {
    class Authorization extends React.Component {
      render() {
        if (ability.can(action, subject)) {
          return <WrappedComponent {...this.props} />;
        } else {
          return <Redirect to="/" />;
        }
      }
    }

    return Authorization;
  };
