import { Transition } from "@headlessui/react";
import {
  SearchIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/outline";
import { useEffect, useRef, useState } from "react";
import { useTable, usePagination } from "react-table";

const availablePageSizes = [5, 10, 25, 35, 50];

export const Table = ({
  renderAddBtn,
  columns,
  data,
  onRowClick,
  fetchData,
  loading,
  totalCount,
  pageCount: controlledPageCount,
  pageIndex: controlledPageIndex,
  pageSize: controlledPageSize,
  searchString: controlledSearchString,
}: any) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: controlledPageIndex,
        pageSize: controlledPageSize || availablePageSizes[0],
      }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    usePagination
  );

  const searchRef = useRef<HTMLInputElement>(null);
  const [searchString, setSearchString] = useState(
    controlledSearchString || ""
  );

  const onSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setSearchString(searchRef.current?.value ?? "");
    }
  };

  const handleSearch = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setSearchString(searchRef.current?.value ?? "");
  };

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    fetchData({ pageIndex, pageSize, searchString });
  }, [fetchData, pageIndex, pageSize, searchString]);

  return (
    <>
      {/* <pre>
        <code>
          {JSON.stringify(
            {
              pageIndex,
              pageSize,
              pageCount,
              canNextPage,
              canPreviousPage,
            },
            null,
            2
          )}
        </code>
      </pre> */}
      {loading && (
        <div className="full-page-loader">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-white h-20 w-20"></div>
        </div>
      )}
      <div className="flex items-center mb-4 select-none">
        <div className="flex-1">{renderAddBtn}</div>
        <div className="flex-1 text-xs text-center text-gray-500 font-light hidden md:block">
          Showing {pageIndex * pageSize + 1} to&nbsp;
          {pageIndex * pageSize + page.length} of {totalCount} entries
        </div>
        <div className="flex-1 text-right">
          <Transition
            appear={true}
            show={true}
            enter="transition ease-in-out duration-1000"
            enterFrom="transform-gpu translate-x-6 opacity-0"
            enterTo="transform-gpu translate-x-0 opacity-100"
          >
            <div className="group relative mx-auto text-gray-600">
              <input
                ref={searchRef}
                className="bg-white h-8 w-36 sm:w-48 md:w-52 px-5 pr-10 rounded-md shadow-sm text-xs focus:outline-none"
                type="search"
                name="search"
                placeholder="Search"
                defaultValue={searchString}
                onKeyDown={onSearchKeyDown}
              />
              <button
                onClick={handleSearch}
                type="submit"
                className="absolute right-0 top-0 mt-2 mr-4"
              >
                <SearchIcon className="transition-opacity duration-300 text-gray-600 h-4 w-4 text-current opacity-30 group-hover:opacity-60" />
              </button>
            </div>
          </Transition>
        </div>
      </div>
      <table
        {...getTableProps()}
        className="w-full border-separate text-sm"
        style={{ borderSpacing: "0 10px" }}
      >
        <thead className="text-xs uppercase">
          {headerGroups.map((headerGroup) => (
            <tr className="text-left" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className="py-4 px-4 font-medium"
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading
            ? new Array(5).fill(0).map((_) => (
                <tr className="animate-pulse rounded-l-lg rounded-r-lg bg-white shadow-sm transition-transform duration-300 ease-out transform hover:scale-102">
                  {new Array(columns.length).fill(0).map((_) => (
                    <td className="animate-pulse first:rounded-l-lg last:rounded-r-lg py-4 px-4">
                      <div className="h-4 bg-gray-100 rounded"> </div>
                    </td>
                  ))}
                </tr>
              ))
            : page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    className="cursor-pointer rounded-l-lg rounded-r-lg bg-white shadow-sm transition-transform duration-300 ease-out transform hover:scale-102"
                    {...row.getRowProps()}
                    onClick={() => onRowClick(row.original)}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          className="first:rounded-l-lg last:rounded-r-lg py-3 px-4"
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-between mt-4 mb-16">
        <div className="flex-1 pagination select-none">
          <button
            className="focus:outline-none mr-2 px-3 py-2 rounded-lg text-gray-600 hover:shadow disabled:shadow-none disabled:text-gray-400"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <ChevronDoubleLeftIcon className="text-current h-3 w-3" />
          </button>
          <button
            className="focus:outline-none mr-2 px-3 py-2 rounded-lg text-gray-600 hover:shadow disabled:shadow-none disabled:text-gray-400"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <ChevronLeftIcon className="text-current h-3 w-3" />
          </button>

          {/* Previous ellipses */}
          {pageIndex >= 2 && (
            <button
              onClick={() => gotoPage(pageIndex - 2)}
              className="hidden lg:inline mr-2 px-3 py-2 rounded-lg text-gray-600 text-xs"
            >
              ...
            </button>
          )}

          {/* Previous page number */}
          {canPreviousPage && pageIndex >= 1 && (
            <button
              className="focus:outline-none mr-2 px-3 py-1 rounded-lg text-gray-600 hover:shadow disabled:shadow-none disabled:text-gray-400"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <span className="h-3 w-3 text-xs">{pageIndex}</span>
            </button>
          )}
          <button
            className="focus:outline-none mr-2 px-3 py-1 bg-white shadow-sm rounded-md"
            disabled={!canPreviousPage}
          >
            <span className="h-3 w-3 text-xs">{pageIndex + 1}</span>
          </button>

          {/* Next page number */}
          {canNextPage && pageIndex < pageOptions.length - 1 && (
            <button
              className="focus:outline-none mr-2 px-3 py-1 rounded-lg text-gray-600 hover:shadow disabled:shadow-none disabled:text-gray-400"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <span className="h-3 w-3 text-xs">{pageIndex + 2}</span>
            </button>
          )}

          {/* Next ellipses */}
          {pageIndex < pageOptions.length - 2 && (
            <button
              onClick={() => gotoPage(pageIndex + 2)}
              className="hidden lg:inline mr-2 px-3 py-2 rounded-lg text-gray-600 text-xs"
            >
              ...
            </button>
          )}

          <button
            className="focus:outline-none mr-2 px-3 py-2 rounded-lg text-gray-600 hover:shadow disabled:shadow-none disabled:text-gray-400"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <ChevronRightIcon className="text-current h-3 w-3" />
          </button>
          <button
            className="focus:outline-none mr-2 px-3 py-2 rounded-lg text-gray-600 hover:shadow disabled:shadow-none disabled:text-gray-400"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <ChevronDoubleRightIcon className="text-current h-3 w-3" />
          </button>
        </div>

        <div className="flex-1 text-right">
          <select
            value={pageSize}
            className="select-none relative w-20 py-2 px-3 text-center bg-white rounded-md shadow-sm cursor-pointer focus:outline-none sm:text-sm"
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {availablePageSizes.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};
