import { useState, useContext, createContext, useEffect } from "react";

import authService from "@service/auth";
import axios, { setupAxiosConfig, tearDownAxiosConfig } from "axios.config";
import { useStoredRefreshToken } from "@hooks/usePersistedStore";

// Create a new user context and define what it will contain.
const AuthContext = createContext<{
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  generateNewToken: (minWait?: number) => Promise<any>;
  fetchUser: () => Promise<void>;
}>({
  user: null,
  login: async (email: string, password: string) => {},
  logout: async () => {},
  generateNewToken: async (minWait?: number) => {},
  fetchUser: async () => {},
});

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = () => {
  return useContext(AuthContext);
};

// Provider component that wraps the app and makes auth object available to any child component that calls useAuth().
export function ProvideAuth({ children }: any) {
  const auth = useProvideAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [refreshToken, setRefreshToken] = useStoredRefreshToken<string | null>(
    null
  );

  useEffect(() => {
    if (!refreshToken) {
      setUser(null);
    }
  }, [refreshToken]);

  // Login the user and make sure to save the user to state.
  const login = async (email: string, password: string) => {
    const response = await authService.login(email, password);

    setupAxiosConfig(response.data.token, response.data.refreshToken);
    await fetchUser();
    setRefreshToken(response.data.refreshToken);

    return response.data;
  };

  const logout = async () => {
    tearDownAxiosConfig();
    setRefreshToken(null);
  };

  const fetchUser = async () => {
    const response = await authService.getSelf();

    setUser(response);
  };

  const generateNewToken = async (minWait = 0) => {
    try {
      const max = new Promise((resolve) => {
        setTimeout(() => resolve(true), minWait);
      });

      if (!refreshToken) {
        throw Error();
      }

      const [response] = await Promise.all([
        authService.generateNewAccessToken(refreshToken),
        max,
      ]);

      console.debug("New access token generated from refresh token");

      return response.data;
    } catch (error) {
      logout();
      throw error;
    }
  };

  // Return the user object and auth methods
  return {
    user,
    login,
    logout,
    generateNewToken,
    fetchUser,
  };
}
