import { Route, Redirect } from "react-router-dom";

import { useAuth } from "@hooks/useAuth";

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const authCtx = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        authCtx.user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: {
                next: rest.location.pathname,
                params: rest.location.search || undefined,
              },
            }}
          />
        )
      }
    />
  );
};

export const PublicOnlyRoute = ({ component: Component, ...rest }: any) => {
  const authCtx = useAuth();

  const state = rest.location.state;

  const nextPageLocation = state?.next ?? "/dashboard";
  const nextPageParams = state?.params;

  return (
    <Route
      {...rest}
      render={(props) =>
        authCtx.user ? (
          <Redirect
            to={{ pathname: nextPageLocation, search: nextPageParams }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
