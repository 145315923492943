import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import { useMemo } from "react";

const FieldInput = ({ name, inputProps }: any) => (
  <Field name={name}>
    {({ field, meta: { touched, error } }: any) => (
      <input
        autoComplete="off"
        className={`appearance-none block w-full bg-gray-100 bg-opacity-30 text-gray-800 border-0 border-b-2 rounded-sm h-10 px-4 focus:outline-none focus:border-indigo-400 ${
          touched && error ? "border-red-300" : "border-indigo-200"
        }`}
        {...inputProps}
        {...field}
      />
    )}
  </Field>
);

interface IProps<T> {
  initialValues: T;
  onSubmit: (values: T, actions: FormikHelpers<T>) => void;
  mode: "update" | "create";
}

const UserForm = <T extends object>({
  initialValues,
  onSubmit,
  mode,
}: IProps<T>) => {
  const UserFormSchema = useMemo(() => {
    const schema: any = {
      firstName: Yup.string()
        .max(25, "Too long name entered")
        .required("Required"),
      lastName: Yup.string()
        .max(25, "Too long name entered")
        .required("Required"),
      emailId: Yup.string()
        .email("Email address is invalid")
        .required("Required"),
      phoneNumber: Yup.string()
        .length(10, "Phone number is invalid")
        .required("Required"),
    };

    if (mode === "create") {
      schema.password = Yup.string()
        .min(8, "Password is too short")
        .required("Required");
      schema.confirmPassword = Yup.string().test(
        "passwords-match",
        "Passwords must match",
        function (value) {
          return this.parent.password === value;
        }
      );
    }

    return Yup.object().shape(schema);
  }, [mode]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UserFormSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ isSubmitting, isValid, dirty }: any) => (
        <Form className="md:flex flex-col w-full text-xs" autoComplete="off">
          <div className="md:flex flex-row md:space-x-4 w-full">
            <div className="mb-3 space-y-1 w-full">
              <label className="font-medium text-gray-600 py-2">
                Firstname
              </label>
              <FieldInput name="firstName" inputProps={{ autoFocus: true }} />
              <ErrorMessage
                className="text-red-400"
                name="firstName"
                component="p"
              />
            </div>
            <div className="mb-3 space-y-1 w-full">
              <label className="font-medium text-gray-600 py-2">Lastname</label>
              <FieldInput name="lastName" />
              <ErrorMessage
                className="text-red-400"
                name="lastName"
                component="p"
              />
            </div>
          </div>
          <div className="mb-3 space-y-1 w-full">
            <label className="font-medium text-gray-600 py-2">Email Id</label>
            <FieldInput name="emailId" />
            <ErrorMessage
              className="text-red-400"
              name="emailId"
              component="p"
            />
          </div>
          <div className="mb-3 space-y-1 w-full">
            <label className="font-medium text-gray-600 py-2">
              Phone Number
            </label>
            <FieldInput name="phoneNumber" inputProps={{ maxLength: 10 }} />
            <ErrorMessage
              className="text-red-400"
              name="phoneNumber"
              component="p"
            />
          </div>
          {mode === "create" && (
            <>
              <div className="mb-3 space-y-1 w-full">
                <label className="font-medium text-gray-600 py-2">
                  Password
                </label>
                <FieldInput name="password" inputProps={{ type: "password" }} />
                <ErrorMessage
                  className="text-red-400"
                  name="password"
                  component="p"
                />
              </div>
              <div className="mb-3 space-y-1 w-full">
                <label className="font-medium text-gray-600 py-2">
                  Confirm Password
                </label>
                <FieldInput
                  name="confirmPassword"
                  inputProps={{ type: "password" }}
                />
                <ErrorMessage
                  className="text-red-400"
                  name="confirmPassword"
                  component="p"
                />
              </div>
            </>
          )}
          <div className="w-full">
            <button
              type="submit"
              disabled={!(isValid && dirty) || isSubmitting}
              className="w-full py-3 mt-6 text-sm tracking-wider transition duration-200 ease-in-out bg-slate-blue text-white uppercase rounded-lg outline-none hover:bg-indigo-700 filter hover:drop-shadow-lg disabled:bg-indigo-300 disabled:drop-shadow-none focus:outline-none "
            >
              {mode === "create" ? "Add User" : "Update User"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
