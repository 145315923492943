import { useAsync } from "@hooks/useAsync";
import authService from "@service/auth";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

export const ProfilePage = () => {
  const {
    status,
    value: userData,
    error,
  } = useAsync<User>(authService.getSelf, true);

  return (
    <>
      <BreadcrumbsItem to="/dashboard/my-profile">My Profile</BreadcrumbsItem>
      {status === "success" && <pre>{JSON.stringify(userData, null, 2)}</pre>}
    </>
  );
};

export default ProfilePage;
