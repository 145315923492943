import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";

import { ReactComponent as UndrawImage } from "@images/undraw-icons.svg";
import LoginForm from "./components/LoginForm";
import ResetPasswordForm from "./components/ResetPasswordForm";
import { useStoredRefreshToken } from "@hooks/usePersistedStore";
import { useAuth } from "@hooks/useAuth";

enum CurrentView {
  LoginForm,
  ResetPasswordForm,
}

const Login = () => {
  const authCtx = useAuth();
  const [currentView, setCurrentView] = useState(CurrentView.LoginForm);

  const [refreshToken] = useStoredRefreshToken<string | null>(null);
  const [isCheckingSession] = useState(!!refreshToken);

  const logoutAndReload = async () => {
    await authCtx.logout();
    window.location.reload();
  };

  const gotoResetPassword = () => setCurrentView(CurrentView.ResetPasswordForm);
  const gotoLoginForm = () => setCurrentView(CurrentView.LoginForm);

  const companyName = window.location.hostname.split(".")[0];

  return (
    <div className="h-screen min-w-max w-screen bg-gray-100 p-10 md:p-16">
      <Transition
        className=""
        appear={true}
        show={true}
        enter="transition-all delay-300 duration-1000"
        enterFrom="transform-gpu translate-y-6 opacity-0"
        enterTo="transform-gpu translate-y-0 opacity-100"
      >
        <div className="p-3 rounded-2xl max-w-4xl mx-auto bg-white filter drop-shadow-md flex">
          <div className="select-none flex-none p-4 overflow-hidden bg-slate-blue hidden md:block w-60 rounded-l-xl">
            <Transition
              appear={true}
              show={true}
              className="h-full"
              enter="transition-all delay-300 duration-1000"
              enterFrom="transform-gpu -translate-x-4 opacity-0"
              enterTo="transform-gpu translate-x-0 opacity-100"
            >
              <div className="flex flex-col h-full justify-between">
                <span className="text-white text-xl tracking-widest font-semibold">
                  insight
                </span>
                <div className="py-8 text-center text-white opacity-80">
                  <p>your life's work, powered by our life's work</p>
                </div>
                <div className="relative h-28">
                  <UndrawImage className="h-56 w-52 absolute -bottom-16 opacity-80" />
                </div>
              </div>
            </Transition>
          </div>
          <div className="flex-grow p-4 py-16">
            <div className="relative w-full sm:w-9/12 md:w-3/5 h-full mx-auto">
              {isCheckingSession ? (
                <div className="w-full">
                  <div className="flex flex-col gap-y-4 divide-gray-300">
                    <Transition
                      appear={true}
                      show={currentView === CurrentView.LoginForm}
                      className="w-full"
                      enter="transition-all delay-200 duration-1000"
                      enterFrom="transform-gpu translate-y-6 opacity-0"
                      enterTo="transform-gpu translate-y-0 opacity-100"
                    >
                      <div className="h-64 w-full flex flex-col place-items-center justify-center">
                        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-20 w-20"></div>
                        <p className="mt-2 text-xs font-light text-center select-none">
                          Securely logging you in
                        </p>
                        <Transition
                          appear={true}
                          show={true}
                          className="w-full text-indigo-700 cursor-pointer"
                          enter="transition-all delay-6000 duration-1000"
                          enterFrom="transform-gpu translate-y-4 opacity-0"
                          enterTo="transform-gpu translate-y-0 opacity-100"
                        >
                          <p
                            onClick={logoutAndReload}
                            className="mt-4 text-xs font-light text-center"
                          >
                            Still stuck? Click here to retry
                          </p>
                        </Transition>
                      </div>
                    </Transition>
                  </div>
                </div>
              ) : (
                <Transition
                  appear={true}
                  show={currentView === CurrentView.LoginForm}
                  className="w-full"
                  enter="transition-all delay-500 duration-1000"
                  enterFrom="transform-gpu translate-y-6 opacity-0"
                  enterTo="transform-gpu translate-y-0 opacity-100"
                  leave="transition-all duration-300"
                  leaveFrom="transform-gpu opacity-100 translate-y-0"
                  leaveTo="transform-gpu translate-y-4 opacity-0"
                >
                  <div className="flex flex-col gap-y-4 divide-gray-300">
                    <h1 className="text-2xl font-medium">
                      <div>Log in to your</div>
                      <div>{companyName} account.</div>
                    </h1>
                    <LoginForm />
                    <hr />
                    <p className="text-xs">
                      Issues logging in?{" "}
                      <span
                        className="text-indigo-600 cursor-pointer"
                        onClick={gotoLoginForm}
                      >
                        Reset Password
                      </span>
                    </p>
                  </div>
                </Transition>
              )}
              {/* <Transition
                show={currentView === CurrentView.ResetPasswordForm}
                className="absolute w-full"
                enter="transition-all delay-500 duration-1000"
                enterFrom="transform-gpu translate-y-6 opacity-0"
                enterTo="transform-gpu translate-y-0 opacity-100"
                leave="transition-all duration-300"
                leaveFrom="transform-gpu opacity-100 translate-y-0"
                leaveTo="transform-gpu translate-y-4 opacity-0"
              >
                <div className="flex flex-col gap-y-4 divide-gray-300">
                  <h1 className="text-2xl font-extrabold">
                    <div>Reset password for your</div>
                    <div>{companyName} account.</div>
                  </h1>
                  <ResetPasswordForm />
                  <hr />
                  <p className="text-xs">
                    Remember your password?{" "}
                    <span
                      className="text-indigo-600 cursor-pointer"
                      onClick={gotoLoginForm}
                    >
                      Login here
                    </span>
                  </p>
                </div>
              </Transition> */}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default Login;
