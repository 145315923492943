import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import UserGetView from "./UserGetView";
import UserListView from "./UserListView";
import UserCreateView from "./UserCreateView";
import UserUpdateView from "./UserUpdateView";

export const UsersPage = () => {
  let { path } = useRouteMatch();
  return (
    <>
      <BreadcrumbsItem to="/dashboard/users">Users</BreadcrumbsItem>
      <Switch>
        <Route exact path={path} component={UserListView} />
        <Route path={`${path}/create`} component={UserCreateView} />
        <Route
          path={`${path}/edit/:applicationUserId`}
          component={UserUpdateView}
        />
        <Route path={`${path}/:applicationUserId`} component={UserGetView} />
        <Route render={() => <Redirect to="/404" />} />
      </Switch>
    </>
  );
};

export default UsersPage;
